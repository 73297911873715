import {
    Allow,
    IsBoolean,
    IsEnum,
    IsISO8601,
    IsNotEmpty,
    IsPositive,
    IsString,
    MinLength,
    ValidateIf,
} from 'class-validator';
import { merge, pick } from 'lodash';
import md5 from 'md5';
import { CreatePassenger } from '.';
import { Genders, Honorifics } from '../../../util/constants';
import { Service } from '../../response';
import { BaseModel } from '../base-model';
import { CreateBookingModel } from './create-booking.model';

export class CreatePassengerBooking implements BaseModel {
    @IsString()
    @Allow()
    public Flight_Number: string = '';

    @Allow()
    public additionalServiceId: number = 0;

    @IsString()
    @IsNotEmpty({ message: 'Airline must be provided' })
    public airline = '';

    // @DateAfter(undefined, { message: 'Select a date in the future' })
    @IsISO8601({}, { message: 'Invalid date format' })
    public Date_Of_Travel: string = '';

    @IsISO8601({}, { message: 'Invalid time format' })
    public Date_Of_Arrival: string = '';

    @IsPositive({ message: 'Invalid airport selection' })
    public AirportId: number = 1;

    @MinLength(3, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    public FirstName: string = '';

    @MinLength(3, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    @ValidateIf((o: CreatePassenger) => !!o.MiddleName)
    public MiddleName: string = '';

    @MinLength(3, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    public LastName: string = '';

    @IsEnum(Honorifics, { message: 'Invalid selection' })
    public Designation: Honorifics = Honorifics.Miss;

    @MinLength(3, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    @ValidateIf((o: CreatePassenger) => o.Others)
    public Special_Requirement: string = '';

    @IsBoolean({ message: 'Invalid selection' })
    public Wheel_Chair: boolean = false;

    @Allow()
    public Others: boolean = false;

    @IsEnum(Genders, { message: 'Invalid gender selection' })
    public Gender: Genders = Genders.Female;

    @Allow()
    public Service!: Service;

    @Allow()
    public ServiceId: number = 0;

    constructor(bookingOrService: Service | CreatePassengerBooking) {
        if ('Service' in bookingOrService) {
            merge(
                this,
                pick(bookingOrService, [
                    'Flight_Number',
                    'Date_Of_Arrival',
                    'Date_Of_Travel',
                    //'additionalServiceId',
                    'AirportId',
                    'Service',
                    'airline',
                ] as (keyof CreatePassengerBooking)[])
            );

            return;
        }

        this.Service = pick(bookingOrService, ['price', 'id']) as Service;
    }

    public static hashDetails(booking: CreatePassengerBooking | CreateBookingModel) {
        return md5(
            [booking.Flight_Number, booking.Date_Of_Arrival, booking.Date_Of_Travel, booking.AirportId].join(':')
        );
    }

    public toPassenger(): CreatePassenger {
        return merge(new CreatePassenger(), this);
    }

    public toBooking(): CreateBookingModel {
        const booking = merge(new CreateBookingModel(this.Service), this);
        booking.ServiceId = this.Service.id;
        booking.Passengers = [this.toPassenger()];
        return booking;
    }

    public beforeValidation() {
        this.AirportId = +this.AirportId;
        this.additionalServiceId = +this.additionalServiceId;
    }
}
