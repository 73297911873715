import React from 'react';
import { ChevronLeft } from 'react-feather';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { history, isPreviousLocationWithinApp } from '../../util/history';
import { generateClassNames } from '../../util/misc/generate-class-names';
import { AppLayoutStyle, Header } from './app-layout-style';

export interface TabLink {
    index: number;
    name: string;
    query?: [] | [string, any];
}

const Wrapper: React.FC = ({ children }) => {
    return <AppLayoutStyle>{children}</AppLayoutStyle>;
};

const Body: React.FC<{ className?: string }> = ({ children, className }) => {
    return <div className={generateClassNames('content-body', className)}>{children}</div>;
};

const TabHeader: React.FC<{
    links?: TabLink[];
    activeTab?: number;
    setActiveTab?: React.Dispatch<React.SetStateAction<number>>;
}> = ({ activeTab, setActiveTab, links, children }) => {
    return (
        <div className="tab-header bold" style={{ fontWeight: 600 }}>
            {links && activeTab !== undefined && setActiveTab && (
                <Nav className="tab-nav" tabs>
                    {links.map(({ index, name, query }) => (
                        <NavItem className="tab-nav__item" key={index}>
                            <NavLink
                                className="tab-nav__link"
                                active={index === activeTab}
                                onClick={() => {
                                    setActiveTab(index);

                                    if (query) {
                                        history.push(query.length ? `?${query[0]}=${query[1]}` : '?');
                                    }
                                }}
                            >
                                {name}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
            )}
            {children}
        </div>
    );
};

const TabBody: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({ className, ...props }) => {
    return <div className={generateClassNames('tab-body', className)} {...props}></div>;
};

const HeaderBackButton: React.FC<{
    showIf?: () => boolean;
    action?: () => void;
}> = ({ showIf, action }) => {
    return showIf?.() || isPreviousLocationWithinApp() ? (
        <Link
            className="header__back-button"
            to={'#'}
            onClick={(e) => {
                e.preventDefault();

                if (action && showIf?.()) {
                    action();
                    return;
                }

                history.goBack();
            }}
        >
            <ChevronLeft size={20} strokeWidth={3} />
        </Link>
    ) : null;
};

export const AppLayout = {
    Wrapper,
    Header,
    HeaderBackButton,
    Body,
    TabHeader,
    TabBody,
};
