import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { TabContent, TabPane } from 'reactstrap';
import { AppLayout, AuthLayout, TabLink, TopBar } from '../../components';
import { ApplicationState, TransactionsThunk } from '../../store';
import { TransactionServicesTable } from './components';

export const OtherUsersTransactionServices: React.FC = () => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = React.useState(1);
    const { transactionType, transactionTypeMeta } = useSelector((state: ApplicationState) => state.transactions);
    const { search } = useLocation();
    const pageNumber = React.useMemo(() => +(queryString.parse(search) as any).page || 1, [search]);

    const links: TabLink[] = [
        { name: 'Booking Services', index: 1, query: ['page', 1] },
        { name: 'Funds Services', index: 3, query: ['page', 1] },
        { name: 'Registration Services', index: 2, query: ['page', 1] },
    ];

    useEffect(() => {
        dispatch(TransactionsThunk.retrieveByTransactionType(activeTab - 1, pageNumber));
    }, [dispatch, pageNumber, activeTab]);

    return (
        <AuthLayout showSidebar={false}>
            <TopBar breadcrumbs={[]} />
            <AppLayout.Wrapper>
                <AppLayout.Header>All Transaction</AppLayout.Header>
                <AppLayout.Body>
                    <AppLayout.TabHeader links={links} activeTab={activeTab} setActiveTab={setActiveTab} />

                    <AppLayout.TabBody>
                        <TabContent activeTab={activeTab} className="tab__content">
                            <TabPane tabId={1}>
                                {transactionType.length > 0 ? (
                                    <TransactionServicesTable
                                        isBooking
                                        transactions={transactionType}
                                        meta={transactionTypeMeta}
                                    />
                                ) : (
                                    <p>There are no transactions</p>
                                )}
                            </TabPane>

                            <TabPane tabId={2}>
                                {transactionType.length > 0 ? (
                                    <TransactionServicesTable
                                        transactions={transactionType}
                                        meta={transactionTypeMeta}
                                    />
                                ) : (
                                    <p>There are no transactions</p>
                                )}
                            </TabPane>

                            <TabPane tabId={3}>
                                {transactionType.length > 0 ? (
                                    <TransactionServicesTable
                                        transactions={transactionType}
                                        meta={transactionTypeMeta}
                                    />
                                ) : (
                                    <p>There are no transactions</p>
                                )}
                            </TabPane>
                        </TabContent>
                    </AppLayout.TabBody>
                </AppLayout.Body>
            </AppLayout.Wrapper>
        </AuthLayout>
    );
};
